import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import {
    GRAY_SECONDARY, SECONDARY, WHITE,
} from '@theme/colors';
import { BREAKPOINTS } from '@theme/vars';
import { FONT_24, FONT_DEFAULT, FONT_REGULAR } from './typography';
import { PRIMARY, ERROR, GRAY_25 } from './colors';

// Create a theme instance.
const theme = createMuiTheme({
    typography: {
        ...FONT_DEFAULT,
    },
    palette: {
        primary: {
            main: PRIMARY,
        },
        secondary: {
            main: SECONDARY,
        },
        error: {
            main: red.A400,
        },
        background: {
            default: WHITE,
        },
    },
    breakpoints: {
        values: {
            ...BREAKPOINTS,
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    ...FONT_DEFAULT,
                },
            },
        },
        MuiGrid: {
            root: {
                padding: 0,
                margin: 0,
            },
        },
        MuiTextField: {
            root: {
                ...FONT_REGULAR,
            },
        },
        MuiTypography: {
            h1: {
                ...FONT_24,
                ...FONT_DEFAULT,
            },
            root: {
                ...FONT_DEFAULT,
            },
        },
        MuiRadio: {
            root: {
                color: GRAY_SECONDARY,
                '&$checked': {
                    color: PRIMARY,
                },
            },
        },
        MuiFormControlLabel: {
            label: {
                ...FONT_REGULAR,
            },
            root: {
                marginBottom: -15,
            },
        },
        MuiDrawer: {
            paperAnchorRight: {
                background: 'transparent',
                boxShadow: 'none',
            },
        },
        MuiIcon: {
            root: {
                color: PRIMARY,
            },
        },
        MuiButton: {
            root: {
                ...FONT_REGULAR,
                // borderRadius: 100,
                textTransform: 'none',
            },
        },
        MuiButtonBase: {
            root: {
                ...FONT_DEFAULT,
            },
        },
        MuiInput: {
            input: {
                '&::placeholder': {
                    color: '#98A2B3',
                },
                borderColor: '##D1D5DC',
            },
        },
        MuiBadge: {
            badge: {
                fontWeight: 500,
                backgroundColor: ERROR,
                color: GRAY_25,
            },
        },
    },
});

export default theme;
